.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  padding: 16px 0px 16px 5px;
  width: 100vw;
}

.title {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.title-img {
  padding: 0px 15px;
  width: 23px;
}

.title-name {
  font-size: 23px;
  margin-left: -10px;
}

.nav-user {
  font-size: 13px;
  padding-right: 30px;
  user-select: none;
  padding-right: 32px;
}

.user-name u {
  cursor: default;
}

.login,
.signUp,
.logout,
.wallet,
.user-name {
  margin-right: 14px;
  cursor: pointer;
}