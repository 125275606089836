.radio-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.radio-input {
    width: 13px;
    height: 13px;
    margin-bottom: 3.5px;
    margin-right: 4px;
}

.radio-option {
    margin-right: 10px;
}