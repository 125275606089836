.container-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    padding: 0px 10px;
    transition: all 0.3s ease-in-out;
}

.input-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.nonClick {
    border: 1px solid #c7c6c6;
}

.click {
    border: 1px solid #111111;
}

.input-invalid {
    border-color: red;
}

.icon-email{
    margin-top: 1.5px;
    margin-right: 2px;
}

.icon-password {
    cursor: pointer;
}