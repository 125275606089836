.history-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 13px 4px 15px;
}

.history-scroll-container {
    flex: 1;
    overflow: hidden;
    min-height: 0;
}

.table-trading-history {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #E3E8EC;
    border-bottom: 1px solid #E3E8EC;
    table-layout: fixed;
}

#historyBody {
    border-top: none;
}

#trading-history-cancel {
    font-size: 12px;
    position: relative;
    border-radius: 5px;
    padding: 6px;
    background-color: rgba(0, 0, 0, 0.16);
    color: #FFFFFF;
    cursor: pointer;
    white-space: nowrap;
}

.table-trading-history thead th {
    font-size: 12.5px;
    text-align: center;
    padding: 11px 0px;
    border-right: 1px solid #E3E8EC;
}

.table-trading-history thead th:nth-child(3) {
    padding: 0px;
}

.table-trading-history thead th:nth-child(4) {
    border-right: none;
}

.table-trading-history thead th div {
    height: 100%;
}

.table-trading-history thead th div:nth-child(1) {
    padding-bottom: 10px;
    border-bottom: 1px solid #E3E8EC;
}

.table-trading-history thead th div:nth-child(2) {
    padding-top: 10px;
}

.table-trading-history thead th div:nth-child(4) {
    border-right: none;
}

.table-trading-history tbody td {
    font-size: 12.5px;
    text-align: center;
    padding: 10px 0px;
    border-bottom: 1px solid #E3E8EC;
    border-right: 1px solid #E3E8EC;
}

.table-trading-history tbody td:nth-child(1) {
    text-align: left;
    padding-left: 7px;
    color: rgba(0, 0, 0, 0.75);
    font-size: 12px;
}

.table-trading-history tbody td:nth-child(4) {
    border-right: none;
}

.trading-history-market {
    font-weight: bold;
}

.trading-history-category {
    font-size: 11.5px;
    font-weight: bold;
    margin-top: 2px;
}

.table-trading-history tbody td:nth-child(3) {
    text-align: right;
    padding-right: 7px;
}

.table-trading-history tbody td:nth-child(4) {
    text-align: right;
    padding-right: 7px;
}

#last-row td {
    border-bottom: none;
}

.tr-un-signed:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}

.un-signed-clicked {
    background-color: #f3f3f3;
}