.crypto-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    height: 125px;
    padding: 30px 25px 0px 30px;
    border-bottom: 1px solid #E3E8EC;
    background-color: #fff;
}

.crypto-detail-skeleton {
    justify-content: center;
    align-items: center;
    height: 134px;
    padding: 4px 5px 17px 5px;
    border-bottom: 1px solid #E3E8EC;
    background-color: #fff;
    overflow: hidden;
}

.crypto-change_rate {
    margin-left: 4px;
    font-size: 18px;
    font-weight: lighter;
}

.crypto-change_price {
    font-size: 20px;
    font-weight: lighter;
}

.crypto-price-container {
    font-weight: bold;
}

.crypto-price {
    font-size: 36px;
}

.crypto-change {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2px;
    white-space: nowrap;
    gap: 3px;
    margin-top: 5px;
}

.rise {
    color: #22ab94;
}

.fall {
    color: #f23645;
}

.even {
    color: black;
}

.summary-container {
    display: flex;
    flex-direction: row;
    height: auto;
    gap: 30px;
    margin-left: 25px;
    overflow-x: auto;

    /* 마스크 레이어 속성을 생성해 그라데이션 효과 */
    mask-image: linear-gradient(to right, black 0%, black calc(100% - 20px), transparent 100%);
    -webkit-mask-image: linear-gradient(to right, black 0%, black calc(100% - 20px), transparent 100%);

    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.summary-container div {
    height: 100%;
}

dl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 5px;
    border-bottom: 1px solid #E3E8EC;
    padding: 5px 0;
    width: 230px;
    gap: 17px;
}

dt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #111111;
    font-size: 13px;
    white-space: nowrap;
}

dd {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    color: black;
    margin-left: auto;
}

dd div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 130px;
}

dd span {
    color: rgba(0, 0, 0, 0.3);
    font-size: 13px;
    font-weight: bold;
    margin-top: 2px;
}

.dd-high_price {
    font-size: 15px;
    color: #22ab94;
}

.dd-low_price {
    font-size: 15px;
    color: #f23645;
}

.img-price_rise {
    filter: invert(48%) sepia(100%) saturate(349%) hue-rotate(120deg) brightness(95%) contrast(84%);
    width: 10px;
    padding-right: 4px;
    padding-left: 1px;
}

.img-price_fall {
    filter: invert(56%) sepia(93%) saturate(4750%) hue-rotate(329deg) brightness(88%) contrast(118%);
    width: 10px;
    padding-right: 4px;
    padding-left: 3px;
}

.img-price_even {
    width: 10px;
    padding-right: 4px;
}

@media (max-width: 767px) {
    .crypto-detail {
        border: none;
        padding-left: 25px;
    }

    .crypto-price-container {
        font-size: 26px;
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    }

    .crypto-change_rate {
        font-size: 15px;
        margin-left: 0;
    }

    .crypto-change_price {
        font-size: 17px;
        margin-top: 0;
    }

    dl {
        width: 100%;
    }

    .img-price_rise,
    .img-price_fall,
    .img-price_even {
        width: 8px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .crypto-detail {
        border: none;
        padding-left: 25px;
    }

    .crypto-price-container {
        font-size: 32px;
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    }

    .crypto-change_rate {
        font-size: 18px;
        margin-left: 0;
    }

    .crypto-change_price {
        font-size: 20px;
        margin-top: 0;
    }

    dl {
        width: 100%;
    }
}