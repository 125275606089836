.list-search {
    height: 36px;
    border-bottom: 1px solid #E3E8EC;
}

.img-search {
    position: relative;
    color: gray;
    height: 16.5px;
    left: 7px;
    top: 3px;
}

.crypto-search {
    margin: 10px 6px;
    border: none;
    width: 80%;
    outline: none;
    height: 17px;
    line-height: 17px;
}

.list-category {
    font-size: 15px;
    display: flex;
    border-bottom: 1px solid #E3E8EC;
}

.list-category span {
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 12px 0px;
}

.list-category span:hover {
    text-decoration: underline;
}

.list-category-clicked {
    font-weight: bold;
    color: #111111;
    border-bottom: 3px solid #111111;
}

.list-thead {
    width: 100%;
    border-bottom: 1px solid #E3E8EC;
}

.list-title {
    border-color: #E3E8EC;
}

#scrollbar-list-table {
    width: 100%;
    overflow-y: auto;
    flex: 1;
}

.list-table {
    flex-direction: column;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
}

.list-table th {
    padding: 11px 16px 11px 0px;
    font-size: 12px;
    white-space: nowrap;
}

.list-table th button {
    cursor: pointer;
    user-select: none;
}

#th-price,
#th-compare,
#th-volume {
    text-align: right;
}

.sort {
    position: relative;
    top: 1px;
    left: 3.5px;
}

.text-rtl {
    direction: rtl;
}

.list-table td {
    font-size: 12px;
    white-space: nowrap;
    border-bottom: 1px solid #E3E8EC;
    padding: 9.5px 11px 9.5px 10px;
    overflow: hidden;
}

#td-price,
#td-compare,
#td-volume {
    text-align: right;
    direction: rtl;
}

.list-table tbody tr {
    cursor: pointer;
}

.list-table tbody tr {
    background-color: #ffffff;
}

.list-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.list-table tbody tr:hover {
    background-color: #e6e6e6;
}

.list-shortcuts-btn-container {
    position: relative;
    bottom: 3px;
}

.div-name {
    padding-left: 12px;
    display: inline-block;
}

.div-name div:nth-child(1) {
    white-space: normal;
}

.div-name div:nth-child(2) {
    font-size: 11px;
}

.td-own-market {
    margin-left: 2px;
    font-size: 11.5px;
}

.change-price {
    font-weight: bold;
    text-decoration: underline;
}

.change-etc {
    font-weight: bold;
}