.kakao-login-button {
    background-color: #FEE500;
    border: none;
    border-radius: 7px;
    color: #000000 85%;
    min-width: 100%;
    height: 52px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    font-size: 16px;
    color: #000000;
    -webkit-tap-highlight-color: transparent;
}

.kakao-login-button img {
    width: 32px;
}

@media (max-width: 767px) {
    .kakao-login-button {
        font-size: 15px;
        height: 50px;
    }

    .kakao-login-button img {
        width: 27px;
    }
}