/* 버튼 기본 스타일 초기화 */
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
}

/* 링크 기본 스타일 초기화 */
a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

.no-drag {
  user-select: none;
}

input:focus {
  outline: none;
}

.rise {
  color: #22ab94;
}

.fall {
  color: #f23645;
}

.even {
  color: #000000;
}