.skeleton-table {
    display: flex;
    flex-direction: column;
    height: auto; 
    padding: 8px 10px;
    overflow: hidden;
}

.skeleton-table-row {
    margin-bottom: 8px; 
}