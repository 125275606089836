.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 30px 0;
    color: #9ca3af;
    font-size: 14px;
}

.divider span {
    padding: 0 12px;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
}

.divider:not(:empty)::before {
    margin-right: .25em;
}

.divider:not(:empty)::after {
    margin-left: .25em;
}