.nav-bar {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    width: 100%;
    border-bottom: 1px solid #E3E8EC;
    user-select: none;
    background-color: #fff;
}

.size-large {
    font-size: 16px;
    padding: 14px 0px;
}

.size-medium {
    font-size: 15px;
    padding: 12px 0px;
}

.size-small {
    font-size: 14px;
    padding: 10px 0px;
}

.nav-item {
    position: relative;
    flex: 1;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
}

.nav-item.active {
    font-weight: bold;
    border-bottom: 3px solid currentColor;
}

.nav-item:hover {
    text-decoration: underline;
}