.auth-button {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 17px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    background-color: #111111;
    cursor: pointer;
    border-radius: 7px;
}

@media (max-width: 767px) {
    .auth-button {
        font-size: 16px;
        height: 48px;
    }
}