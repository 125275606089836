.loading-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.working-spinner-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 18px;
    margin-top: 35px;
    text-align: center;
    padding: 0 20px;
}

.msg-title {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
}

.msg-content {
    font-size: 18px;
    line-height: 1.5;
    word-break: keep-all;
}

/* 모바일 */
@media (max-width: 767px) {
    .working-spinner-msg {
        margin-top: 25px;
    }
    
    .msg-title {
        font-size: 20px;
        margin-bottom: 8px;
    }
    
    .msg-content {
        font-size: 15px;
    }
}