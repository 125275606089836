.placeholder-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.placeholder-container div {
    font-size: 13.5px;
    color: #999999;
    text-align: center;
}