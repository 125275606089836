.common-button {
    font-size: 16px;
    color: #ffffff;
    width: 100%;
    padding: 13px 15px;
    border: none;
    border-radius: 3.5px;
    cursor: pointer;
    font-weight: 400;
}

.common-button.buy {
    background-color: #22ab94;
}

.common-button.sell {
    background-color: #f23645;
}

.common-button.black {
    background-color: #111111;
}

.common-button.white {
    background-color: #ffffff;
    border: 1px solid #111111;
    color: #202630;
    border: 1px solid rgb(228, 229, 230);
}