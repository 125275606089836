.container {
    display: flex;
    flex-direction: column;
    padding: 0px 8px 0px 10px;
    width: 440px;
    height: 100%;
}

.title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.content {
    font-size: 15.5px;
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
    word-break: break-all;
    white-space: pre-wrap;
    margin-right: 10px;
}

.contentDetail {
    font-size: 13.5px;
    color: #313131;
    margin-top: 8px;
}

.modalButton {
    border: none;
    background-color: #fff;
    color: #51518b;
    align-self: flex-end;
    font-weight: 600;
    font-size: 15.5px;
    margin-top: auto;
    height: auto;
    margin-top: auto;
    cursor: pointer;
    white-space: nowrap;
    padding: 6px 12px;
    margin-right: -10px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.modalButton:hover {
    background-color: #f0f0f0;
}

@media (max-width: 767px) {
    .container {
        width: 60vw;
    }
}