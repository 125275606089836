.mobile-detail {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
}

.mobile-detail-header {
    position: sticky;
    top: 0;
    background: white;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    z-index: 100;
}

.mobile-detail-header h2 {
    margin: 0;
    font-size: 18px;
    flex: 1;
}

.mobile-detail-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    gap: 0.3rem;
}

.mobile-content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    background-color: #fff;
}

/* 모바일에서 차트와 호가창 본 조정 */
.mobile-detail-content .chart-container,
.mobile-detail-content .price-detail-container {
    width: 100% !important;
    height: auto !important;
}

.mobile-tab-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}