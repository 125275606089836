.header-nav {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 30px 0px 30px;
}

@media (max-width: 1023px) {
    .header-nav {
        display: none;
    }
}