.auth-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
}

.auth-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 70px;
}

.auth-form-container {
  width: 440px;
}

.form-auth {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 17px;
}

.form-auth input {
  border: none;
  width: 80%;
  height: 45px;
  font-size: 15px;
}

.auth-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 35px;
  font-size: 14px;
  flex-wrap: wrap;
}

.auth-footer:has(.personal-info-container) {
  justify-content: space-between;
}

.personal-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.personal-info-container button {
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.auth-navigate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7px;
  font-weight: 500;
}

.auth-navigate-destination {
  cursor: pointer;
  color: #202630;
  font-weight: 600;
  text-decoration: underline;
  white-space: nowrap;
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-1.5px);
  }

  50% {
    transform: translateX(1.5px);
  }

  75% {
    transform: translateX(-1.5px);
  }

  100% {
    transform: translateX(1.5px);
  }
}

.vibrate {
  animation: vibrate 0.3s infinite;
}

@media (max-width: 767px) {
  .auth-form-container {
    width: 80vw;
  }

  .auth-section {
    padding-bottom: 0px;
  }

  .form-auth input {
    font-size: 14px;
    height: 42px;
  }

  .auth-footer {
    flex-direction: column;
    gap: 12px;
  }

  .auth-footer:has(.personal-info-container) {
    justify-content: center;
  }

  .personal-info-container {
    text-align: center;
    width: 100%;
  }

  .auth-navigate {
    width: 100%;
    justify-content: center;
  }
}