.wallet-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 55px;
    right: 20px;
    width: 430px;
    height: 430px;
    background-color: #FFFFFF;
    border-radius: 2px;
    box-shadow: 2px 2px 10px rgba(107, 107, 107, 0.3);
    cursor: auto;
    z-index: 999 !important;
}

.transfer-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #E3E8EC;
}

.transfer-section span {
    font-size: 16px;
    font-weight: lighter;
    flex: 1;
    text-align: center;
    padding: 17px 0px;
    cursor: pointer;
}

.transfer-section span:hover {
    text-decoration: underline;
}

#deposit-section {
    color: #22ab94;
    border-bottom: 4px solid #22ab94;
    font-weight: bold;
}

#withdraw-section {
    color: #f23645;
    border-bottom: 4px solid #f23645;
    font-weight: bold;
}

.transfer-input,
.change-input {
    background-color: #F5F5F5;
    border-radius: 4px;
    width: 100%;
    height: 95px;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
}

.transfer-input-div,
.change-input-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.change-input {
    margin-top: 20px;
}

.change-input-div {
    display: flex;
}

.warning-border {
    border: 1px solid red;
}

.warning-KRW {
    margin-top: 10px;
    font-size: 14px;
    font-weight: lighter;
    color: red;
}

.transfer-input div,
.change-input div {
    width: 100%;
    padding-top: 12px;
    font-size: 13.5px;
    font-weight: lighter;
}

.transfer-input span,
.change-input span {
    font-size: 17px;
    color: rgba(0, 0, 0, 0.4);
    text-align: end;
    font-weight: bold;
    margin-right: 5px;
}

.transfer-input input,
.change-input input {
    width: 83%;
    height: 60%;
    border: none;
    font-size: 20px;
    background-color: #F5F5F5;
}

.transfer-input input::placeholder,
.change-input input::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-weight: bold;
    font-size: 22.5px;
}

.transfer-input input:focus,
.change-input input:focus {
    outline: none;
}

.transfer-submit {
    position: absolute;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    top: 380px;
    width: 100%;
}

.img-transfer-crypto {
    position: relative;
    width: 24px;
    top: 6px;
}

.wallet-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    min-height: 0;
    padding: 20px 16px 16px 16px;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .wallet-container {
        width: 80vw;
    }
}