body {
  margin: 0;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
  color: #202630;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  height: 60px;
  background-color: #fff;
  padding-left: 10px;
  margin-bottom: 0.5rem;
  flex-shrink: 0;
}

.contents-container {
  display: flex;
  flex: 1;
  min-height: 0;
  width: 100%;
  gap: 0.5rem;
}

.main {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 0;
}

.crypto-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 0;
  background-color: #fff;
  border-radius: 4px;
}

.price-detail {
  display: flex;
  flex-direction: column;
  flex: 0 0 390px;
  min-width: 390px;
  height: 100%;
  gap: 8px;
  border-radius: 4px;
  min-height: 0;
}

.crypto-list {
  display: flex;
  flex-direction: column;
  flex: 0 0 400px;
  min-width: 400px;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
}

/* 태블릿 가로, 랩탑 - 화폐 리스트 숨기기 */
@media (min-width: 1024px) and (max-width: 1279px) {
  .container {
    background-color: #f5f5f5;
    height: calc(var(--vh, 1vh) * 100);
  }

  .crypto-list {
    display: none;
  }
}

/* 모바일과 태블릿에서는 기존 스타일 유지 */
@media (max-width: 1023px) {
  .container {
    background-color: #f5f5f5;
    height: calc(var(--vh, 1vh) * 100);
  }

  .contents-container {
    display: flex;
    flex: 1;
    background-color: #f5f5f5;
    height: 100%;
    width: 100%;
  }

  .crypto-info,
  .price-detail {
    display: none;
  }

  .crypto-list {
    width: 100%;
    /* 최소 너비 없애고 컨테이너 내용물에 따라 자동 조절 */
    min-width: unset;
    flex: 1;
  }
}