#crypto-chart {
    position: relative;
    width: 100%;
    height: 100%;
}

.chart-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;
    min-height: 0;
    overflow: hidden;
}

.trading-header {
    padding: 5px;
    border-bottom: 1px solid #E3E8EC;
}

.div-delimited {
    font-size: 14px;
    padding-left: 12px;
    display: flex;
    align-items: center;
}

.td-delimited {
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
}

.td-delimited-selected {
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
    color: #2962FF;
    font-weight: bold;
}

.td-delimited:hover {
    font-weight: bold;
}

.drop-down {
    display: inline-block;
    position: relative;
    font-size: 14px;
    z-index: 1;
}

.chartSortDate {
    padding-left: 5px;
    padding-right: 1px;
    cursor: pointer;
}

.chartSortDate-selected {
    padding-left: 5px;
    padding-right: 1px;
    cursor: pointer;
    color: #2962FF;
    font-weight: bold;
}

.chartSortDate-selected:hover {
    font-weight: bold;
}

.dd-button {
    display: inline-block;
    border: none;
    padding: 10px 0px;
    cursor: pointer;
    white-space: nowrap;
}

.img-dd {
    width: 9px;
    margin-bottom: 2px;
    margin-left: 5px;
}

.dd-input {
    display: none;
}

.dd-menu {
    position: absolute;
    top: 100%;
    left: 12px;
    border: 1px solid rgb(228, 229, 230);
    border-radius: 2px;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
    padding: 3px 0px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
}

.dd-menu.show {
    opacity: 1;
    visibility: visible;
}

.dd-input:checked+.dd-menu {
    display: block;
}

.dd-menu li {
    padding: 8px 22px 8px 13px;
    cursor: pointer;
    white-space: nowrap;
}

.dd-menu button {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
    background-color: white;
    border: none;
}

.dd-menu li:hover {
    color: #2962FF;
    font-weight: bold;
}

.apexchart-tooltip {
    display: flex;
    flex-direction: row;
    padding: 7px 8px 5px 8px;
    font-size: 12.5px;
    background: #fff !important;
    border: 1px solid transparent !important;
    gap: 7px;
}

.apexchart-tooltip div {
    margin-bottom: 3px;
}

.apexchart-tooltip div:last-child {
    margin-bottom: 0px;
}

/* crosshair 툴팁 스타일 */
.apexcharts-xcrosshairs,
.apexcharts-ycrosshairs {
    stroke-width: 1px;
    stroke: #b6b6b6;
    stroke-dasharray: 4;
}

.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
    background: #202630 !important;
    color: #ffffff !important;
    border: none !important;
    border-radius: 3px !important;
    font-size: 12px !important;
    padding: 6px 8px !important;
    font-weight: 500;
}

/* y축 툴팁 위치 좌측 끝으로 고정 */
.apexcharts-yaxistooltip {
    transform: none !important;
    left: 0 !important;
    margin-left: 15px !important;
}

/* 툴팁의 꼬리를 없앰 */
.apexcharts-xaxistooltip:before,
.apexcharts-xaxistooltip:after,
.apexcharts-yaxistooltip:before,
.apexcharts-yaxistooltip:after {
    display: none !important;
}