.auth-header-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    gap: 5px;
}

.auth-header-name {
    font-size: 30px;
    margin-bottom: 4px;
    font-weight: 600;
}

.auth-header-subtitle {
    font-size: 15px;
    color: #212121;
}

@media (max-width: 767px) {
    .auth-header-name {
        font-size: 26px;
    }

    .auth-header-subtitle {
        font-size: 14px;
    }
}