.trade-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 433px;
    background-color: #fff;
    border-radius: 4px;
}

.trading-contents {
    flex: 1;
    padding: 0px 15px 0px 15px;
}

.trading-footer {
    padding-bottom: 12px;
    background-color: #fff;
}

.asking-section {
    border-bottom: 1px solid #E3E8EC;
}

.closed-section {
    border-bottom: 1px solid #E3E8EC;
}

.trade-nav {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    width: 100%;
    border-bottom: 1px solid #E3E8EC;
    user-select: none;
}

.trade-nav span {
    flex: 1;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
    cursor: pointer;
}

.buying-section {
    font-weight: bold;
    color: #22ab94;
    border-bottom: 3px solid #22ab94;
}

.selling-section {
    font-weight: bold;
    color: #f23645;
    border-bottom: 3px solid #f23645;
}

.trading-head-section {
    padding-top: 4px;
    width: 100%;
}

.trading-section {
    display: block;
    font-size: 15px;
    border-spacing: 0 10px;
    width: 100%;
}

.trading-row {
    display: flex;
    align-items: center;
    padding: 10px 0;
    flex: 1;
    width: 100%;
}

.trading-title {
    font-size: 14px;
    font-weight: bold;
    min-width: 110px;
    white-space: nowrap;
}

.trading-row-contents {
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 16px;
    font-weight: bold;
    flex: 1;
    width: 100%;
}

.trading-row-contents span {
    font-size: 11.5px;
    color: rgba(0, 0, 0, 0.3);
    font-weight: bold;
    padding-top: 1.5px;
    padding-left: 2.5px;
}

.trade-input {
    display: flex;
    flex-direction: row;
    border: 1px solid #c7c6c6;
    border-radius: 2px;
    padding: 6px 2px;
    width: 100%;
}

.trade-input input {
    border: none;
    font-size: 13.5px;
    text-align: end;
    flex: 1;
}

.trade-input span {
    padding: 1.5px 3px 0px 2px;
}

.select-percentage {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    margin-top: -10px;
}

.select-percentage span {
    cursor: pointer;
    flex: 1;
    text-align: center;
    font-size: 13.5px;
    font-weight: 500;
    color: #727272;
}

.select-percentage span:last-child {
    margin-right: 0px;
}

.select-percentage span:first-child {
    margin-left: 0px;
}

.trading-history-section {
    font-weight: bold;
    color: #000000;
    border-bottom: 3px solid #000000;
}

#buy-percentage {
    color: #22ab94;
    font-weight: bold;
}

#sell-percentage {
    color: #f23645;
    font-weight: bold;
}

#non-selected-percentage {
    color: #727272;
}

.trading-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 12px;
    flex-shrink: 0;
    background-color: #fff;
}

.trading-footer button {
    margin: 0px 14px;
}

.trading-submit-non-login {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    gap: 13px;
}

.trading-submit-non-login button:nth-child(1) {
    margin-right: 0px;
}

.trading-submit-non-login button:nth-child(2) {
    margin-left: 0px;
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .trade-section {
        display: flex;
        flex-direction: column;
        min-height: 0;
        flex: 1;
        overflow: hidden;
    }
    
    .trading-contents {
        overflow-y: auto;
        flex: 1;
        height: auto;
    }
}

@media (max-width: 1023px) {
    .trade-section {
        display: flex;
        flex-direction: column;
        min-height: 0;
        flex: 1;
        overflow: hidden;
    }

    .trading-footer {
        padding-bottom: 15px;
    }

    .trade-nav {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        width: 100%;
        background-color: #f8f9fa;
        flex-shrink: 0;
    }

    .trade-nav span {
        flex: 1;
        padding: 9px 0;
        text-align: center;
        cursor: pointer;
        background-color: #f8f9fa;
    }

    .buying-section,
    .selling-section,
    .trading-history-section {
        background-color: #ffffff !important;
        font-weight: bold;
    }

    .buying-section {
        color: #22ab94;
    }

    .selling-section {
        color: #f23645;
    }

    .trading-history-section {
        color: #000000;
    }

    .trading-contents {
        flex: 1;
        overflow-y: auto;
        height: auto;
    }
}